<template>
  <section :class="{ 'scroll-x': !matchSize('md') }">
    <div class="mb-2" v-if="matchSize('md')">
      <UITab :tabs="topicNames" :now="nowTopics" @change="e => (nowTopics = e)" />
    </div>
    <div class="flex items-center gap-4">
      <div
        class="w-full md:min-w-[250px] md:w-[calc((100%-(1rem*4))/5)] rounded-2xl bg-zinc-900 text-white p-4"
        v-for="topic in showTopics"
        :key="topic.name"
      >
        <h2 class="text-xl font-bold flex items-center gap-1">
          {{ topic.name }}
          <component :is="getIcon(topic.name)" class="size-6" />
        </h2>
        <p class="mb-4 text-sm text-white/50">{{ topic.cards.length > 0 ? topic.cards[0].period : '-' }}</p>

        <a
          v-for="card in topic.cards"
          :key="card.cardId"
          @click="openCard(card.cardId)"
          class="-mx-4 px-4 py-2 cursor-pointer flex items-center gap-4 border-t border-white/20 hover:bg-zinc-800"
        >
          <p class="font-mono">{{ card.cardId }}</p>

          <small
            class="ml-auto font-mono"
            :class="{
              'text-yellow-500': topic.name.includes('價格'),
              'text-green-500': topic.name.includes('跌'),
              'text-red-500': topic.name.includes('漲')
            }"
          >
            {{ topic.name.includes('漲') ? '+' : ''
            }}{{
              topic.name.includes('比例')
                ? `${(card[topic.field] * 100).toFixed(2)}%`
                : topic.name.includes('價格')
                ? `$${card[topic.field]}`
                : card[topic.field]
            }}
          </small>
        </a>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ArrowTrendingUpIcon, ArrowTrendingDownIcon, CurrencyYenIcon } from '@heroicons/vue/24/outline'
import { api } from '@/constants/api'

const { matchSize } = useRwd()
const cardStore = useCardStore()
const { nowCard, cardSet } = storeToRefs(cardStore)
const { getCardInfo } = cardStore

const getIcon = name => {
  if (name.includes('價格')) return CurrencyYenIcon
  if (name.includes('漲')) return ArrowTrendingUpIcon
  if (name.includes('跌')) return ArrowTrendingDownIcon
}

const openCard = async id => {
  const card = await getCardInfo(id)
  cardSet.value = []
  nowCard.value = card
}

// fetch data
const topics = ref([])

const topicNames = computed(() =>
  topics.value.map(topic => ({
    name: topic.name,
    field: topic.field,
    icon: getIcon(topic.name)
  }))
)

const showTopics = computed(() => {
  if (matchSize('md')) return topics.value.filter(topic => topic.field === nowTopics.value)
  else return topics.value
})

const nowTopics = ref('price')

const { data, error } = useLazyAsyncData(
  'priceTop',
  async () => {
    return await $fetch(api['priceTop']())
  },
  { server: false }
)

watch(data, () => {
  topics.value = data.value || []
})

watch(error, e => {
  console.error(e)
})
</script>
