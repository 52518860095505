<template>
  <NuxtLink :to="`/member/${member.publicId || ''}`" class="
      flex-none p-0 md:p-4 rounded-xl md:bg-zinc-900 md:hover:bg-zinc-800 
      will-change-[background] transition-colors cursor-pointer 
      min-w-[8rem] max-h-[25rem] w-full h-full snap-center flex flex-col" @click="acitve = true">
    <!-- cover -->
    <div class="rounded-xl grid place-items-center aspect-square" :class="{
      'member-cover': acitve,
    }">

      <UIUser :user="member" :show="{ head: true }" bgClass="h-full w-full shadow-lg shadow-black/50" textClass="" />
      <!-- <UIUser :user="member" class="object-center object-cover select-none h-full w-full" /> -->
    </div>

    <!-- title -->
    <div class="mt-2 md:mt-4 md:min-h-[62px]">
      <div class="hidden md:flex gap-1 font-mono text-zinc-400">
        <UserIcon class="size-4 flex-none" />
        <p class="text-xs truncate">{{ member.publicId }}</p>
      </div>
      <p class="text-center md:text-left pb-1 text-sm md:text-base font-normal md:font-bold !text-white truncate">
        {{ member.name }}
      </p>
      <p class="text-center md:text-left text-xs md:text-sm font-mono text-zinc-400 truncate">
        {{ $t('member.creator') }}
      </p>
    </div>
  </NuxtLink>
</template>
  
<script setup>
import { UserIcon } from '@heroicons/vue/24/solid'

const { member } = defineProps({
  member: {
    type: Object,
    default: {},
  },
})

const acitve = ref(false)
</script>
  
<style scoped>
.member-cover {
  view-transition-name: member-cover;
}

.member-name {
  view-transition-name: member-name;
}
</style>
  