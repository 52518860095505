<template>
  <a :href="'https://youtube.com/watch?v=' + video.ytId" target="_blank"
    class="p-0 md:p-4 rounded-xl md:bg-zinc-900 md:hover:bg-zinc-800 will-change-[background] transition-colors cursor-pointer min-w-[8rem] snap-center flex flex-col"
    @click="acitve = true">
    <!-- cover -->
    <div
      class="md:-mt-4 md:-mx-4 relative aspect-video bg-image rounded-xl shadow-xl bg-[#333333] overflow-hidden grid place-content-center"
      :style="{
        'background-image': 'url(https://img.youtube.com/vi/' + video.ytId + '/0.jpg)',
      }">
    </div>

    <!-- title -->
    <div class="my-2 md:mb-0 md:mt-4 min-h-0 md:min-h-[62px]">
      <h2 class="md:h-[3rem] !text-white line-clamp-2 mb-2">
        {{ video.title || 'Title' }}
      </h2>

      <div class="flex gap-2 items-center">
        <UIUser :user="video?.author" class="flex-none" :show="{ head: true, role: true, name: false }" />

        <div class="w-full overflow-hidden">
          <p class="text-sm text-zinc-400 truncate">
            {{ video?.author?.name || 'User' }}
          </p>
          <div class="flex gap-1 font-mono text-zinc-400">
            <p class="flex-none text-xs truncate">
              {{ video.publishAt ? formatTime(video.publishAt, 'YYYY-MM-DD') : '-' }}
            </p>
          </div>
        </div>
      </div>

    </div>
  </a>
</template>

<script setup>
import { GlobeAltIcon, ChatBubbleOvalLeftEllipsisIcon, FireIcon } from '@heroicons/vue/24/outline'
const { formatTime } = useTool()
const { video } = defineProps({
  video: {
    type: Object,
    default: {},
  },
})

const acitve = ref(false)
</script>
