<template>
  <a @click="doRoute(link)" class="block w-full h-full overflow-hidden flex-none cursor-pointer rounded-xl group">
    <div
      class="w-full h-full aspect-video bg-image grid place-items-stretch items-end default-transition group-hover:scale-105"
      :style="{
        'background-image': 'url(' + cover + ')',
      }">
      <div class="bg-gradient-to-t from-zinc-900/90 to-transparent w-full pt-12 pb-2 md:pb-6 px-2 md:px-6 flex items-end">
        <p class="default-transition text-zinc-300 text-xl md:text-3xl group-hover:text-white font-bold select-none">
          {{ title }}
        </p>
      </div>
    </div>
  </a>
</template>

<script setup>

const { cover, title, link } = defineProps({
  link: {
    type: String,
    default: ''
  },
  cover: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  },
})

const router = useRouter()

const doRoute = (link = '') => {
  if (link.startsWith('http')) {
    window.open(link, '_blank')
  }
  else {
    router.push(link)
  }
}
</script>