<template>
  <TransitionOpacity>
    <UILoading v-if="isPending" />
    <template v-else>
      <Topics v-if="token" />
      <Intro v-else />
    </template>
  </TransitionOpacity>
</template>

<script setup>
import metaData from '@/config/manifest.json'

useSeoMeta({
  title: metaData.name,
  ogTitle: metaData.name,
  description: metaData.description,
  ogDescription: metaData.description,
  ogImage: 'https://jasonxddd.me:9000/bottleneko/bottleneko-v2.png',
  twitterCard: 'summary_large_image'
})

const token = useCookie('token')
const { isPending } = useTimeoutFn(() => { }, 500)
</script>