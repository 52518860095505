<template>
  <section class="min-w-0 text-center flex flex-col items-center gap-2" ref="dailyContainer">
    <div class="w-full min-w-0" v-if="nowCard">
      <h2 class="text-white font-bold text-lg line-clamp-2">{{ nowCard.series }}</h2>
      <span class="text-zinc-300 text-sm"> {{ nowCard.sell }} </span>
    </div>

    <ClientOnly>
      <Swiper
        effect="cards"
        :modules="[SwiperEffectCards, SwiperAutoplay]"
        :autoplay="{ delay: 3000, pauseOnMouseEnter: true }"
        :cardsEffect="{ slideShadows: false, perSlideRotate: 2 }"
        @swiper="getSwiper"
        @afterInit="updateSwiper"
        @slideChange="onSlideChange"
      >
        <SwiperSlide class="cursor-pointer" v-for="(card, cardid) in cards" :key="card.image">
          <!-- cover -->
          <img
            :src="card.image"
            @error="
              e => {
                e.target.src = '/images/loading.png'
              }
            "
            class="select-none daily-card rounded-2xl"
            data-fancybox="daily"
            :data-caption="`${card.series} - ${card.sell}`"
          />
        </SwiperSlide>
      </Swiper>
    </ClientOnly>

    <span v-if="nowCard" class="text-zinc-500 text-xs">
      {{ formatTime(nowCard.today, 'YYYY-MM-DD', { inputFormat: 'YYYYMMDD' }) }}
    </span>
  </section>
</template>

<script setup>
import { api } from '@/constants/api'

const nowId = ref(0)
const nowCard = computed(() => cards.value[nowId.value])
const { formatTime } = useTool()

// swiper
const dailyContainer = ref()
const { width, height } = useElementSize(dailyContainer)

const cardOffset = computed(() => (width.value ? Math.floor(width.value / 9 / 3) : 5))

const swiper = ref()

const getSwiper = instance => {
  swiper.value = instance
}
const updateSwiper = () => {
  setTimeout(() => {
    if (swiper.value) {
      swiper.value.params.cardsEffect.perSlideOffset = cardOffset.value
      swiper.value.update()
    }
  }, 1000)
}

const onSlideChange = e => {
  nowId.value = e.activeIndex
}

// fetch data
const cards = ref([])

const { data, error } = useLazyAsyncData(
  'daily',
  async () => {
    return await $fetch(api['daily']())
  },
  { server: false }
)

watch(data, () => {
  cards.value = data.value || []
  setTimeout(() => {
    useFancybox('[data-fancybox]')
    useFancybox('[data-fancybox="daily"]')
  }, 500)
})

watch(error, e => {
  console.error(e)
})
</script>

<style lang="sass" scoped>
.swiper
  @apply w-[55vw] h-[calc(55vw*889/635)]
  @apply sm:w-[30vw] sm:h-[calc(30vw*889/635)]
  @apply md:w-[calc(25rem-8rem)] md:h-[calc((25rem-8rem)*889/635)]

.swiper-slide
  @apply brightness-[.5] h-fit shadow shadow-black/10 will-change-[filter] duration-100 transition-all
  &.swiper-slide-active
    @apply brightness-100
</style>
