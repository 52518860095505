<template>
  <div class="px-4 md:px-6 relative overflow-hidden">
    <div class="header-space" />
    <!-- topics -->
    <template v-for="(topic, topicId) in topics">
      <template v-if="topic.title === 'topics'">
        <div class="-mx-4 md:-mx-6">
          <Swiper
            :modules="[SwiperAutoplay]"
            :loop="true"
            :breakpoints="topicBreackPoints"
            :autoplay="{
              delay: 3000,
              pauseOnMouseEnter: true
            }"
          >
            <SwiperSlide v-for="(item, id) in topic.items" :key="`topic-${id}`">
              <ItemTopic :title="item.data.title" :cover="item.data.cover" :link="item.data.link" />
            </SwiperSlide>
          </Swiper>
        </div>

        <h2 class="title">
          {{ $t('home.daily') }}
          <NuxtLink
            to="/daily"
            class="md:ml-auto p-1 md:p-2 rounded-full flex items-center gap-1 shadow font-bold text-zinc-700 bg-white shadow-zinc-900/50"
          >
            <ClockIcon class="size-5 stroke-2" />
            <span class="text-sm hidden md:block">
              {{ $t('home.history') }}
            </span>
          </NuxtLink>
        </h2>

        <div class="bg-black/20 rounded-xl p-4 grid items-center">
          <UIDaily />
        </div>

        <template v-if="user.role === 'member'">
          <h2 class="title">
            <StarIcon class="text-amber-500 size-6" />
            {{ $t('home.priceTrend') }}
          </h2>
          <PriceTop />
        </template>
      </template>

      <template v-else-if="topic.title === 'videos'">
        <h2 class="title">
          {{ $t('social.trending') }}
        </h2>
        <div class="-mx-4 md:mx-0 md:py-4">
          <Swiper
            :modules="[SwiperAutoplay]"
            :breakpoints="videoBreackPoints"
            :autoplay="{
              delay: 3000,
              pauseOnMouseEnter: true
            }"
          >
            <SwiperSlide v-for="(item, id) in topic.items" :key="`yt-${id}`">
              <ItemVideo :video="item.data" />
            </SwiperSlide>
          </Swiper>
        </div>

        <!-- <h2 class="title">廣告區塊</h2>
        <div class="-mx-4 md:mx-0 md:py-4">
          <div class="rounded-3xl bg-pink-500 h-[200px]" />
        </div> -->
      </template>

      <template v-else>
        <h2 class="title">
          <NuxtLink :class="{ 'hover:underline hover:underline-offset-4': topic.link }" :to="topic.link">
            {{ topic.title }}
          </NuxtLink>
          <NuxtLink
            v-if="topic.link"
            :to="topic.link"
            class="flex items-center ml-auto p-1 md:p-2 font-bold text-zinc-500 hover:text-zinc-300 text-sm"
          >
            {{ $t('more') }}
            <ChevronRightIcon class="size-4" />
          </NuxtLink>
        </h2>
        <section class="show-container">
          <template v-for="(item, id) in topic.items" :key="id">
            <ItemMember v-if="item.type === 'member'" :member="item.data" />
            <ItemSocialInfo v-if="item.type === 'social'" :social="item.data" />
            <ItemDeck v-else-if="item.type === 'deck'" :deck="item.data" />
            <ItemSeries v-else-if="item.type === 'series'" :series="item.data" />
            <ItemProduct v-else-if="item.type === 'product'" :product="item.data" />
          </template>
        </section>
      </template>

      <ItemAd v-if="topicId % 3 === 0 && topicId > 0" class="-mx-4 md:mx-0 my-4" type="home" />
    </template>
  </div>
</template>

<script setup>
import { ClockIcon, ChevronRightIcon } from '@heroicons/vue/24/outline'
import { StarIcon } from '@heroicons/vue/24/solid'
import { api } from '@/constants/api'

const { user } = storeToRefs(useGlobalStore())

const { data: topics = [] } = useFetch(api['topics']())

// swaper setting
const { genRwdObject } = useRwd()
const topicBreackPoints = computed(() => ({
  0: { slidesPerView: 1.1, spaceBetween: 10, centeredSlides: true },
  ...genRwdObject([
    { slidesPerView: 1.2, spaceBetween: 15, centeredSlides: true },
    { slidesPerView: 1.3, spaceBetween: 15 },
    { slidesPerView: 1.3, spaceBetween: 20, centeredSlides: true },
    { slidesPerView: 1.6, spaceBetween: 20, centeredSlides: true }
  ])
}))
const videoBreackPoints = computed(() => ({
  0: { slidesPerView: 1.2, spaceBetween: 10, centeredSlides: true },
  ...genRwdObject([
    { slidesPerView: 1.5, spaceBetween: 15, centeredSlides: true, loop: true },
    { slidesPerView: 3, spaceBetween: 15, loop: true },
    { slidesPerView: 4, spaceBetween: 20, loop: true },
    { slidesPerView: 6, spaceBetween: 20, loop: false }
  ])
}))
</script>

<style lang="sass" scoped>
.title
  @apply flex items-center gap-2 mt-4
</style>
